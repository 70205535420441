module.exports = [{
      plugin: require('D:/cabracab/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('D:/cabracab/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Open Sans","sans-serif","Roboto","Roboto Condensed"]}},
    },{
      plugin: require('D:/cabracab/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
